import React, { Component } from 'react';

const initData = {
    iconClass: "far fa-lightbulb text-primary mr-1",
    preHeading: "Simple",
    preHeadingspan: "Gifting",
    heading: "It's gifting, not guesswork",
    headingText: "Olive has all the tools you need to organize your next gifting event. Spend less time figuring out your shopping list, and more time with the people you love.",
    headingTexttwo: "Olive has all the tools you need to organize your next gifting event. Spend less time figuring out your shopping list, and more time with the people you love."
}

const data = [
    {
        image: "/img/screenshot_1.png"
    },
    {
        image: "/img/screenshot_2.png"
    },
    {
        image: "/img/screenshot_3.png"
    },
    {
        image: "/img/screenshot_4.png"
    },
]

class ScreenshotSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                                    <i className={this.state.initData.iconClass} />
                                    <span className="text-primary">{this.state.initData.preHeading}</span> {this.state.initData.preHeadingspan}
                                </span>
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {data.map((item, idx) => {
                                    return (
                                        <div key={`so_${idx}`} className="single-screenshot">
                                            <img src={item.image} alt="" />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ScreenshotSection;